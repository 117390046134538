import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();
const clock = new THREE.Clock();
clock.start();

//#region Letter meshes
let text = 'GA WEG!';
let letters = splitText(text);
const letterSpacing = .02;
const wordSpacing = .2
let letterMeshes = [];
let lettersVisible = [];
const group = new THREE.Group();
const pivot = new THREE.Group();

const loader = new FontLoader();
loadLetters();

function splitText(text) {
    let result = [];
    let words = text.split(' ');
    for (let i = 0; i < words.length; i++) {
        let word = words[i].split('');
        result.push(word);
    }
    return result;
}

function loadLetters() {
    loader.load( '/fonts/helvetiker_bold.typeface.json', function ( font ) {
        let letterCount = 0;
        for (let i = 0; i < letters.length; i++) {
            letterCount += letters[i].length;
        }
        
        let offset = 0;
        let index = 0;
        // Make mesh for every letter
        for (let i = 0; i < letters.length; i++) {
            for (let j = 0; j < letters[i].length; j++) {
                let textGeo = new TextGeometry( 
                    letters[i][j], {
                    font: font,
            
                    size: 0.5,
                    height: .2,
                    curveSegments: 5,
            
                    bevelEnabled: true,
                    bevelThickness: 0.01,
                    bevelSize: .03,
                    bevelSegments: 4
                });

                textGeo.computeBoundingBox();
                const width = textGeo.boundingBox.max.x - textGeo.boundingBox.min.x;
                const textMat = new THREE.MeshStandardMaterial({metalness: 0.5, roughness: 0.5});
                textMat.color.set(0xffffff);
                textMat.transparent = true;
                textMat.opacity = 1;
                letterMeshes[index] = new THREE.Mesh( textGeo, textMat);
                letterMeshes[index].name = index.toString();

                lettersVisible[index] = true;
                letterMeshes[index].position.set(i * wordSpacing + offset, 5, 0);
                letterMeshes[index].rotation.x = 0;
                letterMeshes[index].rotation.y = Math.PI * 2;
                group.add(letterMeshes[index]);

                offset += width + letterSpacing;
                index++;
            }
        }

        letterMeshes[letterMeshes.length - 1].position.y = 0;
        letterMeshes[letterMeshes.length - 1].position.z = 5;
        letterMeshes[letterMeshes.length - 1].scale.set(3,3,3);
        group.position.set(-0.5 * offset, 0, 0);

        scene.add(group);
        scene.add(pivot);
        pivot.add(group);
        pivot.position.z = group.position.z;
        // Pivot's center is (0,0,0)
        animateLetters();
    });
}

let animations = [];
function animateLetters() {
    for (let i = 0; i < letterMeshes.length - 1; i++) {
        animations.push(gsap.to(letterMeshes[i].position, {duration: 1, delay: (i + 1) * 0.3, y:0, ease:'expo.in'}));
    }
    animations.push(gsap.to(letterMeshes[letterMeshes.length - 1].position, {duration: 0.8, delay: letterMeshes.length * 0.3, z:0, ease:'expo.in'}));
    animations.push(gsap.to(letterMeshes[letterMeshes.length - 1].scale, {duration: 0.8, delay: letterMeshes.length * 0.3, x:1, y:1, z:1, ease:'expo.in'}));
    animations.push(gsap.to(pivot.rotation, {duration:0.8, delay: letterMeshes.length * 0.3 + 1, z: -2 * Math.PI, ease:'expo.inOut', repeat:-1, repeatDelay: 3}));
}

function resetAnimations() {
    for (let i = 0; i < animations.length; i++) {
        animations[i].restart(true);
    }
}
//#endregion

//#region Explosions
let movementSpeed = 0.1;
let totalObjects = 500;
let objectSize = 0.05;
let colors = [0x4245C2, 0x456CCC, 0x4682B4, 0x45B3CC, 0x42C2B7];
let currentColor = 0; // Index of the current color in above array

let dirs = [];
let parts = [];

function particleExplosion(x,y,z)
{
  let geometry = new THREE.BufferGeometry();//new THREE.Geometry();
  
  let newDirs = [];
  let positions = [];
  for (let i = 0; i < totalObjects; i ++) 
  {
    positions.push(x);
    positions.push(y);
    positions.push(z);

    newDirs.push({
        x:(Math.random() * movementSpeed)-(movementSpeed/2),
        y:(Math.random() * movementSpeed)-(movementSpeed/2),
        z:(Math.random() * movementSpeed)-(movementSpeed/2)}
    );
  }
  dirs.push(newDirs);

  geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
  let material = new THREE.PointsMaterial( { size: objectSize,  color: colors[currentColor] });
  let particles = new THREE.Points( geometry, material );
  this.object = particles;
  this.status = true;
  
  this.xDir = (Math.random() * movementSpeed)-(movementSpeed/2);
  this.yDir = (Math.random() * movementSpeed)-(movementSpeed/2);
  this.zDir = (Math.random() * movementSpeed)-(movementSpeed/2);
  
  scene.add( this.object  ); 
  
  this.update = function(index) {
    if (this.status == true){
        let vertices = this.object.geometry.attributes.position.array;
        for (let i = 0; i < totalObjects * 3; i += 3) {
            vertices[i] += dirs[index][i / 3].x;
            vertices[i + 1] += dirs[index][i / 3].y;
            vertices[i + 2] += dirs[index][i / 3].z;
        }
        this.object.geometry.attributes.position.needsUpdate = true;
    }
  }
}

window.addEventListener('mousedown', (event) => {
    event.preventDefault();
    raycaster.setFromCamera(cursor, camera);
    if (prevIntersect != null) {
        explodeLetter(prevIntersect);
    }
});

function explodeLetter(letter) {
    let index = parseInt(letter.name);
    if (lettersVisible[index]) {
        let min = letter.geometry.boundingBox.min;
        let max = letter.geometry.boundingBox.max;
        let origin = {
            x: letter.position.x + group.position.x + ((max.x - min.x) / 2),
            y: letter.position.y + group.position.y + ((max.y - min.y) / 2),
            z: letter.position.z + group.position.z + ((max.z - min.z) / 2)
        }
        parts.push(new particleExplosion(origin.x, origin.y, origin.z));
        letter.material.opacity = 0.0;
        if (index !== lettersVisible.length - 1) {
            letter.position.y = -5;
        }
    
        lettersVisible[index] = false;
        if (lettersVisible.every((v) => !v)) {
            setTimeout(() => {
                resetAnimations();
                for (let i = 0; i < lettersVisible.length; i++) {
                    lettersVisible[i] = true;
                    letterMeshes[i].material.opacity = 1;
                    // if (i === lettersVisible.length)
                    //     letterMeshes[i].position.y = 0;
                    // else
                    //     letterMeshes[i].position.y = 5;
                }
            }, 2000);
        }
    }
}

//#endregion

//#region Bear

// Floor
// const geometry = new THREE.PlaneGeometry(10,10);
// const material = new THREE.MeshStandardMaterial( {color: 0x808080, side: THREE.DoubleSide} );
// const plane = new THREE.Mesh( geometry, material );
// plane.position.y = -3;
// plane.rotation.x = Math.PI / 2;
// scene.add(plane);

// Bear
const fbxLoader = new FBXLoader();
let bear;
fbxLoader.load(
    '/assets/bear.fbx',
    (object) => {
        bear = object;
        bear.children[0].intensity = 0;
        bear.scale.set(0.002,0.002,0.002);
        bear.position.set(-13.5,0.29,0.1);
        bear.rotation.y = 0.5 * Math.PI;
        bear.visible = false;
        scene.add(bear);
    }
);

window.addEventListener('keydown', (e) => {
    if (e.key === 'k' && bear != undefined) {
        bearRun();
    }
});

let bearAnimation;
let bearRunning = false;
let bearDirection = new THREE.Vector3(1,0,0);
let bearReverse = false;
function bearRun() {
    if (bearAnimation == undefined) {
        bearAnimation = gsap.to(bear.position, {duration: 3.5, delay: 0, x:13.5, ease:'none', onComplete: bearHide, onReverseComplete: bearHide});
        bearRunning = true;
        bear.visible = true;
    }
    else if (!bearRunning) {
        // Walk to right first, next time to the left and keep alternating
        if (bearReverse) {
            bearAnimation.reverse(0);
        }
        else {
            bearAnimation.restart(true);
        }
        bearRunning = true;
        bear.visible = true;
    }
}

function bearHide() {
    bearRunning = false;
    bear.visible = false;

    // Turn the bear around
    if (!bearReverse) {
        bear.rotation.y = -0.5 * Math.PI;
        bearDirection.x = -1;
    }
    else {
        bear.rotation.y = 0.5 * Math.PI;
        bearDirection.x = 1;
    }
    bearReverse = !bearReverse;
}

//#endregion

//#region Lighting
const ambient = new THREE.AmbientLight(0xffffff, 0.2);
scene.add(ambient);

const spotlight = new THREE.SpotLight(0xffffff, 0.2);
spotlight.angle = 2;
spotlight.target = pivot;
spotlight.position.set(0, 2, 3);
scene.add(spotlight);

//#endregion

//#region Canvas
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () =>
{
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

    if(!fullscreenElement)
    {
        if(canvas.requestFullscreen)
        {
            canvas.requestFullscreen()
        }
        else if(canvas.webkitRequestFullscreen)
        {
            canvas.webkitRequestFullscreen()
        }
    }
    else
    {
        if(document.exitFullscreen)
        {
            document.exitFullscreen()
        }
        else if(document.webkitExitFullscreen)
        {
            document.webkitExitFullscreen()
        }
    }
})
//#endregion

//#region Camera
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 3
scene.add(camera)

// Mouse movement
const cursor = {
    x: 0,
    y: 0
}

window.addEventListener('mousemove', (event) => {
    cursor.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	cursor.y = -( event.clientY / window.innerHeight ) * 2 + 1;
})
//#endregion

//#region Render
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
const raycaster = new THREE.Raycaster();
let prevIntersect;

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const tick = () =>
{
    // Mouse-controlled camera movement
    camera.position.x = Math.sin(cursor.x * Math.PI*.5) * 4
    camera.position.y = cursor.y *4;
    if (camera.position.x > -0.4 * Math.PI && camera.position.x < 0.4 * Math.PI
        && camera.position.y > -0.4 * Math.PI && camera.position.y < 0.4 * Math.PI){
        camera.position.z = Math.cos(cursor.x * Math.PI*.4) * 4;
    }
    camera.position.x = clamp(camera.position.x, -0.4 * Math.PI, 0.4 * Math.PI);
    camera.position.y = clamp(camera.position.y, -0.4 * Math.PI, 0.4 * Math.PI);
    camera.lookAt(pivot.position);

    // Particle animation
    let pCount = parts.length;
    while(pCount--) {
        parts[pCount].update(pCount);
    }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);

    // Hover effect
    raycaster.setFromCamera(cursor, camera);
    const intersects = raycaster.intersectObject(group, true);
    // Change letter color
    if (intersects.length > 0) {
        if (prevIntersect == null) {
            currentColor = Math.floor(Math.random() * (colors.length - 1));
            prevIntersect = intersects[0].object;
            prevIntersect.material.color.set(colors[currentColor]);
        }
    }
    else {
        if (prevIntersect != null) {
            prevIntersect.material.color.set(0xffffff);
            prevIntersect = null;
        }
    }

    // Bear update
    if (bearRunning) {
        raycaster.set(bear.position, bearDirection);
        const hit = raycaster.intersectObject(group, true);
        if (hit.length > 0) {
            if (hit[0].distance < 0.2) {
                explodeLetter(hit[0].object);
                currentColor = Math.floor(Math.random() * (colors.length - 1));
            }
        }
    }
}
tick();
//#endregion